<template>
  <div>
    <div class="text-center">
      <p class="text-h6 mb-0">备案进出口商服务协议</p>
    </div>
    <p>
      本服务协议（“协议”）于 2022 年 3 月 1 日（“生效日期”）由以下双方签署：
    </p>
    <p>
      <span>
        <strong>RSA e-Commerce and Digital Logistics DWC LLC</strong>，DWC
        许可证号为10218，其办事处位于迪拜物流城，邮政信箱：644313，迪拜物流城 -
        阿拉伯联合酋长国迪拜（“<u>RSA 自由区</u>”），（“<u>RSA</u>”
        或“<u>服务提供商</u>”）
      </span>
    </p>
    <p>和</p>
    <p class="border-bottom highlighted text-center grey lighten-4">
      {{ sellerDetails.legal_name }}
    </p>
    <p>
      <span>(“<u>卖方</u>”).</span>
    </p>
    <p>
      <span>
        鉴于，RSA 和卖方提议签订本合同，根据该合同，RSA
        将根据本协议的条款和条件向卖方提供服务（“<u>服务</u>”）。
      </span>
    </p>
    <p>为此，双方现约定如下：</p>
    <ul class="num-style-ul">
      <li class="main-point">
        <p class="text-h6">提供服务/期限。</p>
        <p>
          <span>
            服务应包括：进出口服务，包括为卖方在 DWC3
            自由区（“<u>指定区</u>”）和阿联酋大陆提供的进出口服务，包括登记进口商
            (“<u>IOR</u>”) 和登记出口商 (“<u>EOR</u>”) 服务。
          </span>
        </p>
        <p>
          根据本协议，RSA
          自由区将代表卖方在指定区域的海关备案上担任自由区进口商和自由区出口商，RSA
          大陆将代表卖方在海关备案上担任大陆进口商。 RSA 自由区和 RSA
          大陆将分别负责遵守指定区域和阿联酋大陆的规则和规定。 RSA
          将代表卖方持有与卖方库存有关的进口和/或报关单的正式和商业证据，并在任何政府机构提出要求时向卖方提供这些证据。
        </p>
        <p>
          本协议自生效日起生效，初始期限为一(1)年。此后，本协议将自动续签，每次续签一(1)年，除非任何一方至少在当前协议期限的最后一天之前六十(60)天向另一方发出书面通知，解除本协议。
        </p>
      </li>
      <li>
        <p class="text-h6">双方的义务：</p>
        <ul class="up-rom-style-ul ml-10">
          <li>
            <p class="text-h6">RSA :</p>
            <ul class="low-rom-style-ul ml-10">
              <li>
                <p>进口到指定区域：</p>
                <ul class="num-style-ul ml-10">
                  <li>
                    <p>代表卖方在指定区域内记录进口货物。</p>
                  </li>
                  <li>
                    <p>遵守当地的进口法规。</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>进口到阿联酋大陆：</p>
                <ul class="num-style-ul ml-10">
                  <li>
                    <p>代表卖方作为出口商记录离开指定区域的货物。</p>
                  </li>
                  <li>
                    <p>代表未注册的个人作为阿联酋大陆境内货物的进口商。</p>
                  </li>
                  <li>
                    <p>
                      使用自己的名义完成与卖方产品进口相关的所有必要海关手续，并代表卖方的个人客户支付相应的关税和费用，费用由卖方个人承担。
                    </p>
                  </li>
                  <li>
                    <p>
                      向卖方提供海关当局要求的产品清关所需文件的指南和清单。
                    </p>
                  </li>
                  <li>
                    <p>
                      创建、保存和维护与关税、增值税支付相关的所有适当记录和文件，并在需要时按适用法律的要求按时向适当的政府机构完成和提交申报表或报告。
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>出口到世界其他地区：</p>
                <ul class="num-style-ul ml-10">
                  <li>
                    <p>代表卖方作为出口商记录离开指定区域的货物。</p>
                  </li>
                  <li>
                    <p>
                      使用自己的名义完成与卖方产品出口相关的所有必要海关手续。
                    </p>
                  </li>
                  <li>
                    <p>创建、保存和维护所有适当的记录和文件。</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>客户退货和原产地退货：</p>
                <ul class="num-style-ul ml-10">
                  <li>
                    <p>
                      如果客户要求退货，请完成与将产品退回指定区域相关的所有必要进口海关手续。
                    </p>
                  </li>
                  <li>
                    <p>
                      如果卖方提出退货请求，请完成与将产品从指定区域退回原产国相关的所有必要出口海关手续。
                      卖方将继续负责协调装运，包括选择承运人并支付费用。
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>其他：</p>
                <ul class="num-style-ul ml-10">
                  <li>
                    <p>
                      RSA
                      同意担任货物拼箱、始发地和目的地清关的承运人，并提供海运/空运服务。
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  RSA作为IOR，有责任向相关海关当局报告进口产品的任何损坏或到期情况。
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p class="text-h6">卖方：</p>
            <ul class="num-style-ul ml-10">
              <li>
                <p>
                  卖方在此承诺，根据 RSA 作为
                  IOR，在任何货物中不包含任何根据阿联酋法律列为禁止或限制商品的物品，或任何受任何许可控制或同等限制的物品。
                  产品的销售将由卖方通过市场/最终客户完成。
                  如果与客户/市场发生任何争议，RSA 将对此不承担任何责任。
                </p>
              </li>
              <li>
                <p>
                  卖方特此承诺通过电子邮件分享每一个托运细节IoR。support@rsa.global或者是RSA提供的门户网站。IOR支持须经过RSA对每批货物的确认，RSA将在审查细节(如发票、装箱单等时提供确认)。如果卖方未能按照本规定发出通知，RSA将不对装运负责。适用于阿联酋海关的任何费用将向卖方追偿。
                </p>
              </li>
              <li>
                <p>
                  如果卖方违反或违反任何本协议或适用法律，包括但不限于不准确的货物申报、实物货物与装运文件不匹配、协调系统(HS)代码声明不准确、原籍国声明不准确、产品的价值或重量声明不准确、禁止产品的运输，或由此产生的任何索赔、罚款、罚款、损害、成本或其他费用，所有损失的所有责任和责任应完全由卖方承担，规定的适用费用将除了法律建议的任何其他罚款或追偿费用。
                </p>
              </li>
              <li>
                <p>
                  卖方声明并保证，他们拥有并在此承诺维护与本协议项下提供服务相关的义务和行动相关的所有有效许可证和许可，包括但不限于与产品许可、注册、包装和标签有关的许可（根据目的地市场法规）。任何不合规行为均应由卖方自行负责。
                </p>
              </li>
              <li>
                <p>
                  产品的处置或再出口过程将由海关当局建议并经海关当局批准。如果关税部门提及任何产品或货物的关税结算，卖方将负责结算所有相关费用并支付所有费用。
                </p>
              </li>
              <li>
                <p>
                  如果货物是由市场或物流合作伙伴根据综合运输计划发送的，RSA不负责在业务过程中因其他方的货物而产生的任何责任，反之亦然。所定义的产品清除过程是产品对产品的，RSA在任何情况下都不承担与此相关的任何责任。
                </p>
              </li>
              <li>
                <p>卖方应在任何时候：</p>
                <ul class="alpha-style-ul ml-10">
                  <li>
                    <p>
                      尽早向 RSA 通知将要交付到市场的货物，至少提前二十四 (24)
                      小时通知。
                    </p>
                  </li>
                  <li>
                    <p>申报货物的价值</p>
                  </li>
                  <li>
                    <p>
                      确保货物的内容始终与到达目的地时向有关当局申报的内容相同。
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <p class="text-h6">费用</p>
        <ul class="up-rom-style-ul ml-10">
          <li>
            <p>
              卖方将根据单独共享的最新价目表向RSA支付RSA提供的服务费用。付款将以每批货物为基础，包括：
            </p>
            <ul class="alpha-style-ul ml-10">
              <li>
                <p>运费</p>
              </li>
              <li>
                <p>登记进口商费用</p>
              </li>
              <li>
                <p>阿拉伯联合酋长国迪拜进口清关（根据自由区保税设施）</p>
              </li>
              <li>
                <p>交付至亚马逊的营运中心</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              卖方将按上表规定的费率预付服务支付RSA。根据适用的法律，所有费率都不包括任何税费以及管理/便利费。
            </p>
          </li>
          <li>
            <p>
              除非另有书面说明，RSA向卖方提供的关于任何额外服务的预期费用、支出和费用的任何估计都只是指示性的，对RSA不具有约束力。卖方同意向RSA支付所有服务的实际费用、支付和费用的金额。
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p class="text-h6">责任</p>
        <ul class="up-rom-style-ul ml-10">
          <li>
            <p>在任何情况下，RSA都不承担以下责任：</p>
            <ul class="alpha-style-ul ml-10">
              <li>
                <p>
                  任何损失，包括但不限于索赔、成本和/或损害赔偿（不论这些索赔是否来自第三方索赔）；
                </p>
              </li>
              <li>
                <p>
                  相关的任何利息和罚金以及合理的现金费用（包括根据我方约定提出的索赔）；或
                </p>
              </li>
              <li>
                <p>根据我们的约定进口任何物品应征收的任何税款或其他进口税。</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              如果卖方出口的货物由于卖方的行为或疏忽，或由于RSA完全控制之外的任何原因而被相关当局/国家扣押或扣押，则RSA不承担任何责任。
            </p>
          </li>
          <li>
            <p>
              在任何情况下，RSA都不承担任何间接损失或间接损害或费用，如利润损失、客户损失或声誉损害。
            </p>
          </li>
          <li>
            <p>
              在不影响上述任何限制的情况下，RSA在本协议项下的最高责任上限应为卖方在提出任何索赔前12个月向RSA支付的费用总额。
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p class="text-h6">赔偿</p>
        <p>
          卖方特此同意赔偿、保护
          RSA、其员工、管理人员、董事、代理人和代表，使其免受所有索赔、损失、要求、诉讼、诉讼、责任、罚款、成本和费用的损害，包括但不限于：
          由于 (a)
          卖方、其雇员、高级职员、董事、代理人或代表的重大过失或故意不当行为而产生的处置费用、律师费和法庭费用（统称“损失”）；
          (b) 卖方违反本协议的任何规定；或 (c)
          因履行服务而导致或附带的人身伤害（包括死亡）和财产损失（包括丧失使用能力）（以下情况除外）
          RSA 的重大过失或故意不当行为）。
        </p>
      </li>
      <li>
        <p class="text-h6">终止</p>
        <p>
          <span>
            卖方或
            RSA（“非违约方”）可根据本条款规定的程序，因另一方（“违约方”）的重大违约而终止本协议。一旦发生任何重大违约，非违约方将向违约方提供书面通知，并详细说明重大违约的性质。收到此类通知后，违约方将有机会在收到通知后三十
            (30)
            天内纠正重大违约行为。如果实质性违约未在上述约定的补救期内得到补救，或者如果其性质导致无法补救，则非违约方有权立即终止本协议。
          </span>
        </p>
        <p>
          在本协议期满或因任何原因终止后，RSA应将属于卖方或进口商的所有财产，包括已完成或未完成工作的任何数据、信息、文件归还给卖方，并对卖方或进口商的财产没有留置权。该帐目应在十五(15)天内结清/调和，并将所有款项偿还给另一方，否则未清款项应按12%的年利息支付。
        </p>
        <p>
          各方同意就服务有效终止之日或之前发生的任何装运履行各自的权利和义务，任何应付的款项（无论是未开票还是已开票但卖方未支付）均对卖方具有约束力。
        </p>
      </li>
      <li>
        <p class="text-h6">争议解决</p>
        <p>
          卖方与 RSA
          之间有关本协议条款的任何争议或分歧，如果未能友好解决，则应根据不时修订的《贸易法委员会国际商业仲裁示范法》（1985
          年）提交仲裁。如果卖方和 RSA
          同意，仲裁庭应由一名独任仲裁员组成，如果没有此类协议，仲裁庭应由三名仲裁员组成，卖方和
          RSA
          各指定一名仲裁员，第三名仲裁员应由如此指定的两名仲裁员选择。仲裁地点为阿联酋迪拜，仲裁语言为英语。仲裁庭作出的裁决，包括任何有关此类仲裁费用的裁决，均为终局裁决，对卖方和
          RSA 具有约束力。
        </p>
        <p>
          提及任何事项、争议或仲裁请求和/或由此产生的任何仲裁程序的继续，绝不意味着双方放弃履行各自在本协议项下的义务。
        </p>
        <p>
          <span>
            尽管有上述规定，RSA
            可以选择将任何争议提交阿拉伯联合酋长国迪拜国际金融中心（“DIFC”）的
            DIFC 法院。如果 RSA 是被告方，则必须在根据上述仲裁条款发出索赔通知后
            14
            日内宣布该选择权，并且根据该声明，双方应促使仲裁中止（不作出任何裁决）。如果
            RSA 行使其选择权，卖方现在或以后放弃对在 DIFC
            提起的与本协议有关的任何诉讼的任何异议，双方特此不可撤销地服从 DIFC
            的专属管辖权。
          </span>
        </p>
      </li>
      <li>
        <p class="text-h6">不可抗力</p>
        <p>
          任何一方均不对其履行本协议项下或由本协议引起的任何义务的任何延迟或失败负责，如果该等延迟或失败是由于以下任何不可抗力事件，即天灾、战争、使卖方或RSA无法履行其在本协议项下的义务的任何政府禁令。任何延误超出了合理的时间,可能是一个有效的理由终止协议,因此,当事人应当返回各自的货物,文件和信息在这样一个事件,以专业的方式采取行动,确保风险减轻给对方最好的努力。尽管有上述规定，在不可抗力事件发生期间，RSA有权随时以通知方式终止本协议。
        </p>
      </li>
      <li>
        <p class="text-h6">保密</p>
        <p>
          各方同意，除法律或任何监管机构要求外，不得披露另一方的任何机密信息或与服务有关的任何机密信息。
          该义务应在本协议终止后两 (2) 年内继续有效。
        </p>
      </li>
      <li>
        <p class="text-h6">适用法律</p>
        <p>
          本协议受阿拉伯联合酋长国法律管辖，不适用任何可能导致服从其他法律的法律冲突原则。
        </p>
      </li>
    </ul>
    <p class="my-6">本协议由双方正式授权的代表于生效日期签署。</p>
    <v-row class="mt-4">
      <!-- <v-col>
        <p class="text-h6">RSA自由区</p>
        <p class="my-0 ml-16 sign-div">
          <span>&nbsp;</span>
        </p>
        <p class="text-decoration-overline">(盖章和授权签名)</p>
        <p>日期: ________________________</p>
        <p>阿布舍克·阿杰·沙阿</p>
        <p>RSA 全球首席执行官</p>
      </v-col> -->
      <v-col>
        <p class="text-h6">卖家</p>
        <p class="my-0 ml-16 sign-div">
          <img v-if="sign" :src="sign" class="mx-auto" />
          <span v-else>&nbsp;</span>
        </p>
        <p class="text-decoration-overline">(盖章和授权签名)</p>
        <p>
          <span>
            日期:
            <strong class="highlighted">
              {{ new Date() | localMoment | moment("DD MMM, YYYY") }}
            </strong>
          </span>
        </p>
        <p>
          <span>
            姓名:
            <strong class="highlighted">
              {{ sellerDetails.signatory_name }}
            </strong>
          </span>
        </p>
        <p>
          <span>
            指定:
            <strong class="highlighted"> {{ sellerDetails.job_title }} </strong>
          </span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    sign: {
      type: String,
      default: null,
    },
    sellerDetails: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding-left: 0;
  &.num-style-ul {
    > li {
      list-style: decimal;
    }
  }
  &.up-rom-style-ul {
    > li {
      list-style: upper-roman;
    }
  }
  &.low-rom-style-ul {
    > li {
      list-style: lower-roman;
    }
  }
  &.alpha-style-ul {
    > li {
      list-style: lower-alpha;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding-left: 5px;
  }
}
.sign-div {
  height: 70px;
  width: 100px;
  display: inline-block;
  img {
    max-width: 100%;
  }
}
.highlighted {
  color: blue;
  font-weight: bold;
}
</style>
